.section__padding {
    padding: 6rem 6rem;
}

.footer {
    background-color: white;
}

.sb__footer {
    display: flex;
    flex-direction: column;
}

.sb__footer-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.sb__footer-links_div {
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

a {
    color: black;
    text-decoration: none;
}

.footer-img {
    width: 200px;
    height: 100px;
    margin: 0;
    margin-left: -3.5vh;
}

.footer-h4 {
    font-size: 18px;
    line-height: 17px;
    margin-bottom: 0.9rem;
    color: #AB8A57;
}

.footer-p {
    text-align: left;
    margin: 15px 14vh 10px 0px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: black;
}

hr {
    color: black;
    width: 100%;
    opacity: 0.1;
}

.sb__footer-copyright p {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: black;
    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .sb__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }

    .footer-h4 {
    margin-top: 10px;
    }

}

@media screen and (max-width: 550px) {
    .sb__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .sb__footer-links div {
        margin: 1rem 0;
    }

    .sb__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .sb__footer-below {
        flex-direction: column;
        justify-content: left;
    }

    .sb__footer-below-links {
        flex-direction: column;
    }

    .sb__footer-below-links p {
        margin-left: 0rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .sb__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }

    .sb__footer-below-links p {
        margin-left: 0rem;
        margin-top: 1rem;
    }

    .footer-h4 {
        margin-top: 10px;
    }

    .sb__footer-copyright p {
        font-size: 10px;
    }
}