* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

/* WHATSAPP BUTTON DESIGN*/
.icon-menu {
  position: fixed;
  top: 22%;
  right: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
}

.icon-menu a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: #25D366;
}

.icon-menu a:hover {
  background-color: #ffffff;
  color: #25D366;
}
/*Whatsapp end button design*/

/*ESPECIALIDADES SECTION*/
.especialidade-title {
    font-size: 36px;
    color: #AB8A57;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.especialidade-card-title {
    font-size: 24px;
    color: white;
    padding: 15px;
    font-weight: bold;
}

img {
    position: relative;
    height: 22vh;
    width: 16.4vh;
}

#team .card {
    border-radius: 0;
    margin-top: 10px;
    background-color: #26918C;
    color: white;
    border: none;
    box-shadow: 5px 5px 10px #d0d6d6;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in; 
}

#team .card:hover {
    background-color: #3ba8a2;
    cursor: pointer;
    color: white;
    border: none;
    box-shadow: 5px 5px 10px #4c4e4e;
}

@media (max-width: 600px) {
    #team .card {
        margin: 10px 50px 10px 50px;
    }

    .especialidade-title {
        margin: 5vh 0px 5vh 0px;
    }

    .especialidade-card-title {
        font-size: 20px;
        margin: 0px;
    }
}

@media (min-width: 990px) and (max-width: 1250px) {
    .especialidade-card-title {
        font-size: 20px;
    }
}
/*ESPECIALIDADES SECTION*/

@media (max-width: 600px) {
    .container-filosofia {
        flex-direction: column;
        height: 70vh;
    }

    .filosofia-text{
        font-size: 20px;
        padding:  20px 0px 18px;
    }

    .box-1 {
        height: 30vh;
    }

    .box-2 {
        height: 10vh;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .container-filosofia {
        flex-direction: column;
        height: 80vh;
    }

    .box-2 {
        flex: 1;
        height: 30vh;
    }

    .filosofia-text {
        margin-top: 15%;
    }
}

@media (min-width: 900px) and (max-width: 1150px) {
    .filosofia-text {
      font-size: 20px; 
      margin-top: 17%; 
    }
}

/*Image Gallery design section*/
.container-image-gallery {
    padding: 10px;
    max-width: 70%;
    height: 150vh;
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
}

.v-stretch {
    grid-row: span 2;  
    padding: 10px 20px 10px 20px; 
}

.h-stretch {
    grid-column: span 2; 
    padding: 10px 20px 10px 20px;
}

.big-stretch {
    grid-row: span 2;
    grid-column: span 2;
    padding: 10px 20px 10px 20px;
}

.container-image-gallery div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-image-gallery div img:hover {
    cursor: pointer;
}

@media (max-width: 560px) {
    .v-stretch {
        grid-row: span 1;
        padding: 0px 20px 0px 20px;
    }
    .h-stretch {
        grid-column: span 1;
        padding: 0px 20px 0px 20px;
    }
    .big-stretch {
        grid-row: span 1;
        grid-column: span 1;
        padding: 0px 20px 0px 20px;
    }

    .container-image-gallery {
        height: 180vh;
        display: contents;
        margin: 10px;
    }

    .container-image-gallery div img {
        width: 100%;
        height: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .container-image-gallery {
        padding: 10px 20px 10px 20px;
        max-width: 90%;
        height: 70vh;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
        grid-auto-rows: 250px;
        grid-auto-flow: dense;
    }
}

@media (min-width: 900px) and (max-width: 1150px) {
    .container-image-gallery {
        padding: 10px 20px 10px 20px;
        max-width: 70%;
        height: 76vh;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit,minmax(20px,1fr));
        grid-auto-rows: 250px;
        grid-auto-flow: dense;
    }
}