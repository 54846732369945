.service {
    width: 70%;
    margin: auto;
    margin-top: 5vh;
}

.Service_image {
    width: 35vh;
    height: 30vh;
    box-shadow: 5px 5px 10px #d0d6d6;
    cursor: pointer;
}

.servico_p{
    font-size: 14px;
    text-align: left;
    padding: 20px;
    color: #535353;
}

.servicos_h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 10vh;
    color: #54524e; 
    text-transform: uppercase;
}

.service_box {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 3rem;
    text-align: center;
}

.service_box_item {
    background-color: #f4f9f9;
    padding: 50px 20px 50px 20px;
}

.service_box_item_step {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

span {
    color: #AB8A57;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 300;
}

.span-button:hover {
    background-color: #AB8A57;
    color: white;
    transition: .35s ease-in-out
}

.check-list li {
    position: relative;
    text-align: left;
    color: #535353;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
}
  
.check-list li:before {
      content: '';
      display: flex;
      flex-direction: row;
      position: absolute;
      left: 0;
      top: -2px;
      width: 5px;
      height: 11px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #00a8a8;
      transform-origin: bottom left;
      transform: rotate(45deg);
}

@media screen and  (max-width: 560px){
    .service {
        width: 90%;
    }

    .servicos_h1 {
        font-size: 23px;
    }
}

@media screen and  (max-width: 800px){
    .service_box {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
}