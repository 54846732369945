.service_box_face {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 3rem;
    text-align: center;
}

.service_box_item_face {
    background-color: #f4f9f9;
    padding: 50px 20px 50px 20px;
}

.service_box_item_step_face {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.servico_p_face {
    font-size: 13px;
    text-align: left;
    padding: 18px;
    color: #535353;
}

@media screen and  (max-width: 560px){
    .service {
        width: 90%;
    }

    .servicos_h1 {
        font-size: 23px;
    }
}

@media screen and  (max-width: 900px){
    .service_box_face {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }

    .service {
        width: 60%;
    }
}

@media screen and (min-width: 900px) and (max-width: 1400px){
    .service_box_face {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    .service {
        width: 50%;
    }
}