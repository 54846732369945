.entrar_em_contato {
    height: 60vh;
    margin: 10vh 0;
}

.encontre_h1 {
    font-size: 40px;
    text-align: center;
    color: #fffefe; 
    text-transform: uppercase;
    padding: 50px 0px;
    translate: 0vh -50vh;
}

.encontre_h2 {
    font-size: 28px;
    text-align: center;
    color: #fffefe; 
    text-transform: none;
    padding: 100px 150px;
    translate: 0vh -50vh;
}

a:link {
    text-decoration: none;
}

.a_contato {
    color: white;
    text-decoration: none;
    padding: 15px 50px;
}

a:hover {
    text-decoration: none;
    color:#3E3E3E;
    
}

.button_em_contato {
    translate: 0vh -50vh;
    padding: 15px 0px;
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    background-color: #26918C;
    border: 1px #ffffff solid;
}

.button_em_contato:hover {
    border: 1px #ffffff solid; 
    background-color: #ffffff;
    color: #3E3E3E;
    border-radius: 2vh;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; 
}

@media screen and  (max-width: 560px){
    .encontre_h1 {
        font-size: 30px;
    }

    .encontre_h2 {
        font-size: 20px;
        padding: 80px 30px;
    }
}

@media screen and  (max-width: 800px){
    .encontre_h1 {
        font-size: 30px;
    }

    .encontre_h2 {
        font-size: 22px;
        padding: 60px 20px;
    }
}