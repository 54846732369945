.image_header_title {
    text-transform: uppercase;
    text-align: end;
    font-size: 80px;
    color: #AB8A57;
    translate: 0vh -50vh;
    border-bottom: 1px #AB8A57 solid;
    width: 40%;
}

@media screen and (max-width: 560px) {
    .image_header_title {
        font-size: 48px;
        width: 60%;
    }
}

@media screen and (min-width: 560px) and (max-width: 900px) {
    .image_header_title {
        font-size: 50px;
    }
}

.ebook-section {
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: flex;
    background-color: #26918C;
    flex: 1;    
    padding: 50px;
    align-items: center;
    justify-content: center;
}
.ebook-image{
    flex: 0;
    height: 40vh;
}

.ebook-section img {    
    height: 60vh;
    width: 74vh;
    margin-top: -10vh;
}

.ebook-guia {
    color: white;
    font-weight: bold;
    text-align: left;
    margin-bottom: -10px;
}

.ebook-gratuito {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: -10px;
    padding: 10px 0px 20px 0px;
    font-weight: normal;
    color: white;
}

.ebook-info {
    color: white;
    font-size: 20px;
    text-align: left;
    height: 8vh !important;
    padding: 5vh 0px 15px 0px;
}

.button-ebook {
    width: 180px;
    height: 43px;
    background-color: white;
    border: none;
    color: #AB8A57;
    text-transform: uppercase;
    padding: 5px;
    margin-top: 5vh;
    border-radius: 40px;
  
    &:hover {
      background-color: #e6dfdf;
    }
  
    &:active {
      background-color: #ffffff;
    }
}

@media (max-width: 600px) {
    .ebook-section {
        flex-direction: column;
        padding-top: 20vh;
        height: 80vh;
    }

    .ebook-description {
        flex: 1;
    }

    .ebook-description .ebook-guia {
        font-size: 28px;
    }

    .ebook-description .ebook-gratuito {
        font-size: 18px;
    }

    .ebook-description .ebook-info {
        font-size: 14px;
    }

    .ebook-image {
        flex: 1;
    }

    .ebook-image img {
        height: 35vh;
        width: 42vh;
        margin-left: -10vh;
        margin-top: 10vh;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .ebook-description .ebook-guia {
        font-size: 28px;
    }

    .ebook-description .ebook-gratuito {
        font-size: 18px;
    }

    .ebook-description .ebook-info {
        font-size: 14px;
    }
    .ebook-image img {
        height: 35vh;
        width: 42vh;  
        margin-top: 2vh;  
    }
}

@media (min-width: 900px) and (max-width: 1150px) {
    .ebook-description .ebook-guia {
        font-size: 30px;
    }

    .ebook-description .ebook-gratuito {
        font-size: 18px;
    }

    .ebook-description .ebook-info {
        font-size: 15px;
        margin-top: 5vh;
    }
    .ebook-image img {
        height: 42vh;
        width: 51vh;  
        margin-top: 1vh;  
    }
}